/**
 * Created by Andrey Popov on 4/4/25.
 */

const ScrollTable = function (dataSource, options) {
    cleverapps.EventEmitter.call(this);

    this.minIconsCount = options.minIconsCount;
    this.clipping = options.clipping;
    this.scrollDisabled = options.scrollDisabled;

    this.dataSource = dataSource;

    this.rows = this.dataSource.getData();

    this.dataSource.on("add", this.addRow.bind(this), this);
    this.dataSource.on("remove", this.removeRow.bind(this), this);
    this.dataSource.on("reorder", this.reorder.bind(this), this);
};

ScrollTable.prototype = Object.create(cleverapps.EventEmitter.prototype);
ScrollTable.prototype.constructor = ScrollTable;

ScrollTable.prototype.getRows = function () {
    return this.rows;
};

ScrollTable.prototype.addRow = function (newRow, insertIndex) {
    this.rows.splice(insertIndex, 0, newRow);
    this.trigger("add", newRow, insertIndex);
};

ScrollTable.prototype.removeRow = function (rowId) {
    const row = this.rows.find((r) => r.id === rowId);
    const removeIndex = this.rows.indexOf(row);

    this.rows.splice(removeIndex, 1);
    this.trigger("remove", removeIndex);
};

ScrollTable.prototype.reorder = function () {
    const updated = this.dataSource.getData();

    let to;
    for (let from = 0; from < this.rows.length; from++) {
        const updatedRow = updated[from];

        if (updatedRow && this.rows[from].id !== updatedRow.id) {
            for (to = from + 1; to < this.rows.length; to++) {
                if (this.rows[to].id === updatedRow.id) {
                    break;
                }
            }

            const temp = this.rows[from];
            this.rows[from] = this.rows[to];
            this.rows[to] = temp;

            this.trigger("swap", from, to);
        }
    }
};

ScrollTable.prototype.destructor = function () {
    runCleaners(this);

    this.dataSource.destructor();
};