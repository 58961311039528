/**
 * Created by mac on 2019-08-25
 */

var BaseWindow = cc.Node.extend({
    listBundles: function () {
        return [];
    },

    ctor: function () {
        this._super();

        const args = arguments;

        cleverapps.scenes.getRunningScene().addChild(this);

        this.setAnchorPoint(0.5, 0.5);
        this.setLocalZOrder(BaseWindow.WINDOWS_ZORDER);

        cleverapps.windows.add(this);

        this.openTime = Date.now();

        this.bundles = this.listBundles.apply(this, arguments) || [];
        if (cleverapps.skins.getBundles()) {
            this.bundles = this.bundles.concat(cleverapps.skins.getBundles());
        }
        const personsBundles = this.getPersonBundles();
        if (personsBundles) {
            this.bundles = this.bundles.concat(personsBundles);
        }
        this.bundles.push("windows");
        this.bundles = cleverapps.unique(this.bundles);
        this.bundles = this.bundles.filter((name) => bundles[name]);
        if (this.bundles.length === 0) {
            this.runWindow(args);
            return;
        }

        const start = Date.now();

        const onSuccess = this.createListener(() => {
            this.runWindow(args);

            cleverapps.ConsoleStream.sendLoadingTime(`window ${this.name} ${Date.now() - start}ms`);
        });

        const onFailure = this.createListener(() => {
            this.deleteSelf();

            new RestartWindow();
        });

        cleverapps.focusManager.clearUpdateTimeout();
        this.load(onSuccess, onFailure);
    },

    getPerson: function () {
        return undefined;
    },

    getPersonBundles: function () {
        const personBundles = [];
        const person = this.getPerson();
        if (person) {
            let left, right;

            if (person.left || person.right) {
                left = person.left;
                right = person.right;
            } else {
                left = person;
            }

            [left, right].filter(Boolean).forEach((personRole) => {
                const personObject = cleverapps.persons.getRole(personRole.role || personRole);
                if (personObject && personObject.bundle) {
                    personBundles.push(personObject.bundle);
                }
            });
        }

        return personBundles;
    },

    load: function (onSuccess, onFailure) {
        cleverapps.bundleLoader.loadBundles(this.bundles, { onSuccess, onFailure });
    },

    updateSize: function () {
        const sceneSize = cleverapps.resolution.getSceneSize();
        this.setContentSize(sceneSize.width, sceneSize.height);
        this.setPositionRound(sceneSize.width / 2, sceneSize.height / 2);
    },

    setupChildren: function () {

    },

    completeAnimationOnResize: function () {
        this.window.stopAllActions();
        this.window.setScale(1);
    },

    onPopUpAnimationFinished: function () {

    },

    inflateWindow: function () {

    },

    onResize: function () {
        if (cleverapps.windows.currentWindow() !== this && !(this instanceof WaitWindow)) {
            return;
        }

        this.children.forEach((child) => {
            child.onResize();
        });

        this.updateSize();

        if (this.initializedSuccess) {
            this.setupChildren();
            this.completeAnimationOnResize();
        }
    },

    isCurrent: function () {
        return cleverapps.windows.currentWindow() === this;
    },

    runWindow: function (args) {
        this.updateSize();
        this.onWindowLoaded.apply(this, args);
        this.setupChildren();
        this.inflateWindow();

        this.initializedSuccess = true;

        if (this.closeWhenLoaded) {
            this.close();
        } else {
            this.displaySelf();
        }
    },

    hideSelf: function () {
        if (cleverapps.windows.currentWindow() === this) {
            this.setVisible(false);

            if (this.styles.shadow) {
                cleverapps.windows.trigger("hideShadow");
            }

            this.onHide();
        }
    },

    getEventNodes: function () {
        return [this];
    },

    displaySelf: function () {
        if (this.initializedSuccess && cleverapps.windows.currentWindow() === this) {
            cleverapps.windows.onHideLoading();
            cleverapps.focusManager.updateControlsVisibility();

            this.setVisible(true);
            cleverapps.focusManager.setEventNodes(this.getEventNodes());

            cleverapps.windows.trigger(this.styles.shadow ? "showShadow" : "hideShadow", this.shouldDelayShadow());

            if (this instanceof ForceView) {
                cleverapps.windows.trigger("moveShadowUp");
            }

            if (cleverapps.environment) {
                cleverapps.environment.onOpenWindow();
            }

            this.popUpAnimation();
            this.onShow();

            cleverapps.windows.trigger("showUpWindow");
        }
    },

    shouldDelayShadow: function () {
        return false;
    },

    deleteSelf: function () {
        cleverapps.windows.delete(this);
        this.removeFromParent();
    },

    stop: function () {
        cc.eventManager.removeListeners(this, true);
    },

    beforeCloseAnimation: function (callback) {
        callback();
    },

    close: function () {
        if (this.closed === true) {
            cleverapps.throwAsync(`Closing already closed window: ${this.name}`);
        }

        if (this.closed) {
            return;
        }

        if (!this.initializedSuccess) {
            this.closeWhenLoaded = true;
            return;
        }

        if (this.closeButton instanceof BandButton) {
            this.closeButton.hide();
        }

        if (this.tapToContinue) {
            this.tapToContinue.runAction(new cc.Sequence(
                new cc.FadeOut(0.3),
                new cc.Hide()
            ));
        }

        if (this.homeButton) {
            this.homeButton.runAction(new cc.Sequence(
                new cc.FadeOut(0.3),
                new cc.Hide()
            ));
        }

        if (this.windowFooter) {
            this.windowFooter.runAction(new cc.Sequence(
                new cc.FadeOut(0.3),
                new cc.Hide()
            ));
        }

        if (this.windowTitle) {
            this.windowTitle.hide();
        }

        if (this.decors) {
            this.decors.hideAll();
        }

        if (this.content) {
            this.content.children.forEach((component) => {
                if (component.hide) {
                    component.hide();
                }
            });
        }

        cleverapps.windows.remove(this);
    },

    onClose: function () {

    }
});

BaseWindow.WINDOWS_ZORDER = 22;
