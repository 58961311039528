/**
 * Created by slava on 15/8/17
 */

levels.FriendRequests = function () {
    cleverapps.EventEmitter.call(this);
    this.lastServerReload = 0;
    this.requestsLogs = {};
    this.requestsPayloads = {};
    this.notProcessed = [];
    this.processedIds = [];

    this.onChangeListener = function () {};

    cleverapps.abExperiments.whenGroupsUpdated(() => {
        this.load();

        if (!cleverapps.flags.requestsEnabled) {
            this.deleteFakeRequests();
        }

        this.initialized = true;
    });

    cleverapps.timeouts.setInterval(() => {
        if (!this.initialized) {
            return;
        }

        this.immediatelyProcess();
        this.serverReload();
        FakeRequestSender.sendIfNeed();
    }, cleverapps.parseInterval("1 minute"));
};

levels.FriendRequests.prototype = Object.create(cleverapps.EventEmitter.prototype);
levels.FriendRequests.prototype.constructor = levels.FriendRequests;

levels.FriendRequests.prototype.load = function () {
    const data = cleverapps.dataLoader.load(DataLoader.TYPES.FRIEND_REQUESTS);
    if (!data) {
        this.notProcessed = [];
        this.processedIds = [];
    } else {
        this.notProcessed = [];
        const availableTypes = this.listAvailableTypes();
        data.notProcessed = data.notProcessed.filter((request) => availableTypes.indexOf(request.type) !== -1);
        for (let i = 0; i < data.notProcessed.length; i++) {
            this.notProcessed.push(levels.FriendRequest.createFriendRequest(data.notProcessed[i]));
        }
        this.processedIds = data.processedIds;
    }

    this.requestsLogs = cleverapps.dataLoader.load(DataLoader.TYPES.FRIEND_REQUESTS_SEND_TIME) || {};
    this.requestsPayloads = cleverapps.dataLoader.load(DataLoader.TYPES.FRIEND_REQUESTS_FROM_PAYLOAD) || {};
};

levels.FriendRequests.prototype.save = function () {
    const notProcessed = [];
    for (let i = 0; i < this.notProcessed.length; i++) {
        notProcessed.push(this.notProcessed[i].getData());
    }
    cleverapps.dataLoader.save(DataLoader.TYPES.FRIEND_REQUESTS, {
        notProcessed,
        processedIds: this.processedIds
    });
};

levels.FriendRequests.prototype.idInNotProcessed = function (id) {
    for (let i = 0; i < this.notProcessed.length; i++) {
        if (this.notProcessed[i].getId() === id) {
            return true;
        }
    }
    return false;
};

levels.FriendRequests.prototype.lastNotProcessedId = function () {
    for (let i = this.notProcessed.length - 1; i >= 0; i--) {
        if (this.notProcessed[i].canView()) {
            return this.notProcessed[i].id;
        }
    }
    return false;
};

levels.FriendRequests.prototype.existNotProcessed = function () {
    for (let i = 0; i < this.notProcessed.length; i++) {
        if (this.notProcessed[i].canView()) {
            return true;
        }
    }
    return false;
};

levels.FriendRequests.prototype.getNotProcessedIdsByType = function (type) {
    return this.notProcessed.filter((request) => request.getData().type === type);
};

levels.FriendRequests.prototype.existRequestFrom = function (from) {
    for (let i = 0; i < this.notProcessed.length; i++) {
        if (this.notProcessed[i].canView() && this.notProcessed[i].from === from) {
            return true;
        }
    }
    return false;
};

levels.FriendRequests.prototype.existRequestTypes = function (types) {
    for (let i = 0; i < this.notProcessed.length; i++) {
        if (types.indexOf(this.notProcessed[i].type) >= 0) {
            return true;
        }
    }
    return false;
};

levels.FriendRequests.prototype.processById = function (id) {
    let changed = false;
    let onServer = false;

    for (let i = 0; i < this.notProcessed.length; i++) {
        if (this.notProcessed[i].getId() === id) {
            if (this.notProcessed[i].onServer()) {
                onServer = true;
            }
            this.notProcessed.splice(i, 1);
            changed = true;
            break;
        }
    }
    if (onServer) {
        this.processedIds.push(id);
        this.deleteOnServerById(id);
    }

    if (changed) {
        this.onChangeListener();
        this.trigger("load");
    }

    this.save();
};

levels.FriendRequests.prototype.immediatelyProcess = function () {
    this.notProcessed.forEach((request) => {
        const requestType = levels.FriendRequest.REQUEST_TYPES[request.type];
        if (requestType.immediatelyProcess) {
            request.process();
        }
    });
};

levels.FriendRequests.prototype.onServerLoad = function (data) {
    const newProcessedIds = [];
    let added = false;
    const availableTypes = this.listAvailableTypes();
    if (!Array.isArray(data)) {
        cleverapps.throwAsync(`FriendRequests.onServerLoad data type is - ${typeof data}`);
        data = [];
    }
    data = data.filter((request) => availableTypes.indexOf(request.type) !== -1);
    for (let i = 0; i < data.length; i++) {
        if (this.processedIds.indexOf(data[i].id) >= 0) {
            this.deleteOnServerById(data[i].id);
            newProcessedIds.push(data[i].id);
        } else if (!this.idInNotProcessed(data[i].id)) {
            this.notProcessed.push(levels.FriendRequest.createFriendRequest({
                id: data[i].id,
                from: data[i].fromUserId,
                type: data[i].type
            }));
            added = true;
        }
    }
    this.processedIds = newProcessedIds;
    this.save();

    if (added) {
        this.immediatelyProcess();
        this.onChangeListener();
        this.trigger("load");
    }
};

levels.FriendRequests.prototype.saveRequestLogTime = function (toUserId, type) {
    if (!levels.FriendRequest.REQUEST_TYPES[type].validateTime) {
        return;
    }

    if (!this.requestsLogs[toUserId]) {
        this.requestsLogs[toUserId] = {};
    }

    this.requestsLogs[toUserId][type] = Date.now();

    cleverapps.dataLoader.save(DataLoader.TYPES.FRIEND_REQUESTS_SEND_TIME, this.requestsLogs);
};

levels.FriendRequests.prototype.requestCanNotBeSend = function (toUserId, type) {
    if (!levels.FriendRequest.REQUEST_TYPES[type].validateTime) {
        return false;
    }

    if (!this.requestsLogs[toUserId] || !this.requestsLogs[toUserId][type]) {
        return false;
    }

    return this.requestsLogs[toUserId][type] + levels.FriendRequests.SEND_INTERVAL > Date.now();
};

levels.FriendRequests.prototype.sendServerRequest = function (toUserId, type, fromUserId, onSuccess, onError) {
    fromUserId = fromUserId || connector.platform.getUserID();

    const successCallback = function (addedRowsCount) {
        if (addedRowsCount > 0) {
            this.saveRequestLogTime(toUserId, type);
        }

        onSuccess && onSuccess(addedRowsCount);
    }.bind(this);

    cleverapps.RestClient.post(`/friendrequests/create/${encodeURIComponent(fromUserId)}/a`, {
        toUserId,
        type
    }, successCallback, onError);
};

levels.FriendRequests.prototype.deleteOnServerById = function (id) {
    cleverapps.RestClient.post(`/friendrequests/delete/${encodeURIComponent(connector.platform.getUserID())}/a`, {
        id
    });
};

levels.FriendRequests.prototype.serverReload = function (force) {
    if (!cleverapps.synchronizer.synced) {
        return;
    }

    const needReload = force || this.lastServerReload + levels.FriendRequests.RELOAD_INTERVAL < Date.now()
        && cleverapps.flags.requestsEnabled
        && (cleverapps.config.type === "merge" || cleverapps.friends.listIds().length > 0);

    if (needReload) {
        this.lastServerReload = Date.now();
        cleverapps.RestClient.get(`/friendrequests/getmy/${encodeURIComponent(connector.platform.getUserID())}/a`, {}, this.onServerLoad.bind(this));
    }
};

levels.FriendRequests.prototype.deleteFakeRequests = function () {
    const filtered = this.notProcessed.filter((request) => !request.fake);

    if (filtered.length !== this.notProcessed.length) {
        this.notProcessed = filtered;
        this.onChangeListener();
        this.save();
    }
};

levels.FriendRequests.prototype.listAvailableTypes = function () {
    if (cleverapps.config.type === "merge") {
        return [levels.FriendRequest.TYPE_SENT_UNIT];
    }

    let types = [
        levels.FriendRequest.TYPE_STUCK_HELP_COINS
    ];

    if (typeof match3 === "undefined") {
        types = types.concat([
            levels.FriendRequest.TYPE_SENT_COINS,
            levels.FriendRequest.TYPE_ASK_COINS,
            levels.FriendRequest.TYPE_SENT_HELP_COINS,
            levels.FriendRequest.TYPE_ASK_HELP_COINS
        ]);
    }

    if (cleverapps.lives) {
        types = types.concat([
            levels.FriendRequest.TYPE_SENT_HELP_LIVE,
            levels.FriendRequest.TYPE_ASK_HELP_LIVE,
            levels.FriendRequest.TYPE_SENT_LIVE,
            levels.FriendRequest.TYPE_ASK_LIVE
        ]);
    }

    if (cleverapps.starChest && cleverapps.starChest.isEnabled()) {
        types = types.concat(([
            levels.FriendRequest.TYPE_SENT_HELP_STAR,
            levels.FriendRequest.TYPE_ASK_HELP_STAR,
            levels.FriendRequest.TYPE_SENT_STAR,
            levels.FriendRequest.TYPE_ASK_STAR
        ]));
    }

    return types;
};

levels.FriendRequests.prototype.handleFriendRequestFromPayload = function (from, type, id, date) {
    const data = String(from) + String(type) + String(id) + String(date);

    if (this.requestsPayloads[data]) {
        return;
    }

    this.sendServerRequest(connector.platform.getUserID(), type, from, function (addedRowsCount) {
        if (addedRowsCount) {
            this.requestsPayloads[data] = true;
            cleverapps.dataLoader.save(DataLoader.TYPES.FRIEND_REQUESTS_FROM_PAYLOAD, this.requestsPayloads);
        }
    });
};

levels.FriendRequests.sendGift = function (friendId) {
    if (!friendId) {
        cleverapps.notification.create("FriendGift.not_play");
        return;
    }

    if (levels.friendRequests.requestCanNotBeSend(friendId, levels.FriendRequest.TYPE_SENT_UNIT)) {
        cleverapps.notification.create("FriendGift.wait");
        return;
    }

    levels.friendRequests.sendServerRequest(friendId, levels.FriendRequest.TYPE_SENT_UNIT, undefined, (addedRowsCount) => {
        if (addedRowsCount === 0) {
            cleverapps.notification.create("FriendGift.not_received");
        } else {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.FRIEND_BALLOON_SEND, { value: 1 });
            cleverapps.notification.create("FriendGift.send");
        }
    }, (error) => {
        cleverapps.throwAsync(`sendServerRequest error: ${JSON.stringify(error)}`);
    });
};

levels.FriendRequests.RELOAD_INTERVAL = cleverapps.parseInterval("5 minutes");
levels.FriendRequests.SEND_INTERVAL = cleverapps.parseInterval("1 day");

if (cleverapps.config.debugMode) {
    levels.FriendRequests.RELOAD_INTERVAL = cleverapps.parseInterval("1 minute");
    levels.FriendRequests.SEND_INTERVAL = cleverapps.parseInterval("1 minute");
}