/**
 * Created by razial on 16.01.2025.
 */

const SimpleLogic = function () {
    this.currentLocationId = 0;

    this.gardenlogic = this.isGardenLogic();
    this.hoselogic = this.isHoseLogic();
    this.shortmetalogic = this.isShortmetaLogic();
};

SimpleLogic.prototype.isHoseLogic = function () {
    return ["riddles", "crocword"].indexOf(cleverapps.config.name) !== -1;
};

SimpleLogic.prototype.isShortmetaLogic = function () {
    return ["olympics", "epicart"].indexOf(cleverapps.config.name) !== -1;
};

SimpleLogic.prototype.isGardenLogic = function () {
    return ["garden"].indexOf(cleverapps.config.name) !== -1;
};

SimpleLogic.prototype.reset = function () {
    this.currentLocationId = 0;
};

SimpleLogic.prototype.isLocationAvailable = function (locationId) {
    let bundleName;

    if (this.gardenlogic) {
        bundleName = `location_${locationId}`;
        return Boolean(bundles[bundleName] && !bundles[bundleName].meta.debug);
    }
    
    if (this.shortmetalogic) {
        return locationId >= 0;
    }

    if (this.hoselogic) {
        bundleName = `episode_${locationId}`;

        if (cleverapps.config.type === "board" && cleverapps.settings.language !== "en") {
            bundleName += `_${cleverapps.settings.language}`;
        }

        return Boolean(bundles[bundleName] && !bundles[bundleName].meta.debug);
    }

    if (cleverapps.config.rpg) {
        return Boolean(bundles[`episode_${locationId}`]);
    }

    return Boolean(
        bundles[`simple_background_${locationId}`]
        || bundles[`simple_background_horizontal_${locationId}`]
        || bundles[`simple_background_vertical_${locationId}`]
    );
};

SimpleLogic.prototype.getLocationCompleteReward = function () {
    return {
        hard: 50
    };
};

SimpleLogic.prototype.recentAvailableLocation = function (locationId) {
    locationId = parseInt(locationId);

    while (locationId >= 0 && !this.isLocationAvailable(locationId)) {
        locationId--;
    }
    return locationId;
};

SimpleLogic.prototype.getNextLocation = function (locationId) {
    return locationId + 1;
};

SimpleLogic.prototype.getMainLocation = function () {
    return this.recentAvailableLocation(this.currentLocationId);
};

SimpleLogic.prototype.getLocationClass = function () {
    if (this.gardenlogic) {
        return GardenLocation;
    }

    if (this.shortmetalogic) {
        return ShortMetaLocation;
    }

    if (this.hoselogic) {
        return HoseLocation;
    }

    return SimpleLocation;
};

SimpleLogic.prototype.listActiveLocations = function () {
    const locationId = this.recentAvailableLocation(this.currentLocationId);

    if (this.gardenlogic) {
        return [locationId - 2, locationId - 1, locationId].filter(this.isLocationAvailable.bind(this));
    }

    return [locationId];
};

SimpleLogic.prototype.isCompleted = function () {
    if (this.gardenlogic) {
        return !this.isLocationAvailable(this.getNextLocation(this.currentLocationId));
    }

    if (this.shortmetalogic) {
        return false;
    }

    if (this.hoselogic) {
        return !this.isLocationAvailable(this.currentLocationId);
    }

    return !this.isLocationAvailable(this.currentLocationId)
        || cleverapps.meta.location && cleverapps.meta.location.isCompleted() && !this.isLocationAvailable(this.getNextLocation(cleverapps.meta.location.locationId));
};

SimpleLogic.prototype.startLocation = function (locationId) {
    this.currentLocationId = locationId;

    const LocationClass = this.getLocationClass();
    if (LocationClass.ResetLocation) {
        GardenLocation.ResetLocation(locationId);
    }
};

SimpleLogic.prototype.getCurrentLocationId = function () {
    return this.currentLocationId;
};

SimpleLogic.prototype.processChatMessage = function (message) {
    if (message.metha.simple) {
        // todo fix after SimpleAdapter will be removed
        cleverapps.meta.updateInfo({
            current: message.metha.simple.current,
            stars: 0
        });
    }
};
