/**
 * Created by r4zi4l on 09.09.2022
 */

const SnapshotSyncers = {};

SnapshotSyncers.extractors = {
    random: function () {
        return cleverapps.Random.state;
    },

    abtest: function () {
        return cleverapps.abExperiments.groups;
    },

    playSession: function () {
        return cleverapps.playSession.getInfo();
    },

    scene: function () {
        if (typeof Game !== "undefined" && Game.currentGame && Game.currentGame.level && Game.currentGame.level.episodeNo !== undefined && Game.currentGame.level.levelNo !== undefined && !cleverapps.environment.isMainScene()) {
            return {
                episodeNo: Game.currentGame.level.episodeNo,
                levelNo: Game.currentGame.level.levelNo,
                levelType: Game.currentGame.level.levelType
            };
        }

        if (typeof Merge !== "undefined" && Merge.currentMerge) {
            return {
                episodeNo: cleverapps.meta.selectedLocationId(),
                levelNo: 0
            };
        }
    },

    flags: function () {
        return Object.keys(cleverapps.gameModes).filter((flagName) => cleverapps.gameModes[flagName]);
    },

    users: function () {
        const data = CustomSyncers.extractors.users();
        if (data) {
            data.forcedVisited = data.visited;
        }

        return Object.assign(data || {}, cleverapps.info.data, {
            country: cleverapps.country.country,
            payerLevel: cleverapps.paymentsHistory.classify(),
            userIdsHistory: cleverapps.userIdsHistory.ids.join(",")
        });
    },

    placements: function () {
        const data = cleverapps.placements.getInfo();
        data.onceStarts = cleverapps.clone(cleverapps.placements.onceStarts);

        if (cleverapps.fallSale && cleverapps.windows.currentWindow() instanceof FallSaleWindow) {
            delete data.onceStarts.fallSaleWindow;
        }

        return data;
    },

    games: function () {
        if (cleverapps.config.type === "merge") {
            return CustomSyncers.extractors.games();
        }

        const data = typeof Game !== "undefined" && Game.currentGame && Game.currentGame.getInfo();
        if (!data) {
            return;
        }

        if (!data.keypad && Game.currentGame.keypad) {
            data.keypad = Game.currentGame.keypad.getInfo();
        }

        return Object.assign(data, {
            level: Game.currentGame.level.levelNo,
            episode: Game.currentGame.level.episodeNo
        });
    },

    travelBook: function () {
        if (!cleverapps.travelBook) {
            return;
        }

        const data = {};

        cleverapps.travelBook.listAllPages().forEach((page) => {
            const pageData = cleverapps.dataLoader.load(DataLoader.TYPES.TRAVEL_BOOK_EVENTS + page.id);
            if (pageData) {
                data[page.id] = pageData;
            }
        });

        return data;
    },

    travelBookAttention: function () {
        if (!cleverapps.travelBook) {
            return;
        }
        
        const data = {};

        cleverapps.travelBook.listAllPages().forEach((page) => {
            const attention = cleverapps.dataLoader.load(DataLoader.TYPES.TRAVEL_BOOK_ATTENTION + page.id);
            if (attention) {
                data[page.id] = attention;
            }
        });

        return data;
    },

    clan: function () {
        if (!cleverapps.userClan || !cleverapps.userClan.id) {
            return;
        }

        const data = cleverapps.userClan.getInfo();
        data.players = cleverapps.userClan.players.map((player) => player.getInfo());
        data.messages = cleverapps.clanMessages.listMessages().map((message) => message.getInfo());

        return data;
    },

    ads: function () {
        const data = {
            enabled: cleverapps.rewardedAdsManager && cleverapps.rewardedAdsManager.isRewardedSupported(),
            running: connector.ads.isInterstitialPlaying && cleverapps.rewardedAdsManager.prerollStatus !== RewardedAdsManager.PREROL_SHOWING
        };
        if (cleverapps.rewardedAdsManager.prerollStatus === RewardedAdsManager.PREROL_SHOWN) {
            data.prerollStatus = RewardedAdsManager.PREROL_SHOWN;
        }
        return data;
    },

    isLoggedIn: function () {
        return connector.social.isLoggedIn();
    },

    specialEnergyOffer: function () {
        if (typeof SpecialEnergyOffer !== "undefined") {
            return cleverapps.dataLoader.load(DataLoader.TYPES.SPECIAL_LIVES_OFFER_LAST_TIME) || {};
        }
    },

    events: function () {
        if (!cleverapps.eventManager) {
            return;
        }
        return cleverapps.eventManager.events;
    },

    stickersBookAttention: function () {
        if (!cleverapps.stickersBook) {
            return;
        }
        return cleverapps.stickersBook.getAttentionInfo();
    },
    
    noAds: function () {
        return cleverapps.noAds.getState();
    },

    rumble: function () {
        const rumble = cleverapps.knockoutGame && cleverapps.knockoutGame.rumble;
        if (!rumble) {
            return;
        }

        return rumble.getInfo();
    },

    knockout: function () {
        if (typeof Game === "undefined" || !Game.currentGame || !Game.currentGame.competition || !Game.currentGame.competition.options || Game.currentGame.competition.options.type !== "knockout") {
            return;
        }

        const opponentView = cleverapps.scenes.getRunningScene().playersView.opponentView;

        return opponentView.getOpenedPoints && opponentView.getOpenedPoints();
    },

    energyProducts: function () {
        return cleverapps.dataLoader.load(DataLoader.TYPES.ENERGY_PRODUCTS) || {};
    },

    adsLimits: function () {
        return cleverapps.adsLimits.getInfo();
    },

    tutorial: function () {
        if (cleverapps.tutorial) {
            return cleverapps.tutorial.getInfo();
        }
    },

    friendRequests: function () {
        if (levels.friendRequests) {
            const notProcessed = [];
            for (let i = 0; i < levels.friendRequests.notProcessed.length; i++) {
                notProcessed.push(levels.friendRequests.notProcessed[i].getData());
            }

            return {
                notProcessed,
                processedIds: levels.friendRequests.processedIds
            };
        }
    }
};

SnapshotSyncers.importers = {
    random: function (serverData) {
        cleverapps.Random.state = serverData || 0;
    },

    abtest: function (serverData) {
        cleverapps.dataLoader.save(DataLoader.TYPES.ABTEST, serverData);
    },

    playSession: function (serverData) {
        cleverapps.playSession.updateInfo(serverData);
    },

    users: function (serverData) {
        if (cleverapps.user) {
            serverData.forcedVisited = serverData.forcedVisited || Date.now();
            cleverapps.user.updateInfo(serverData);
        }
    },

    settings: function (serverData) {
        const hashParams = cleverapps.getRequestParameters(location.hash);
        const language = hashParams.lang || serverData.language;

        if (!language || language.length === 0) {
            return;
        }

        cleverapps.settings.setLanguage(language);
        cleverapps.settings.updateDependents();

        Messages.loadLanguage(language, () => {});
    },

    flags: function (serverData) {
        if (!serverData || cleverapps.config.wysiwygMode) {
            return;
        }

        Object.keys(cleverapps.gameModes).forEach((flagName) => {
            cleverapps.gameModes[flagName] = serverData.indexOf(flagName) !== -1;
        });
        cleverapps.gameModes.save();
    },

    placements: function (data) {
        if (data) {
            cleverapps.dataLoader.save(DataLoader.TYPES.PLACEMENTS, data);
        }

        cleverapps.placements.load();
    },

    travelBook: function (data) {
        if (!cleverapps.travelBook) {
            return;
        }

        cleverapps.travelBook.listAllPages().forEach((page) => {
            if (data[page.id]) {
                cleverapps.dataLoader.save(DataLoader.TYPES.TRAVEL_BOOK_EVENTS + page.id, data[page.id]);
            } else {
                cleverapps.dataLoader.remove(DataLoader.TYPES.TRAVEL_BOOK_EVENTS + page.id);
            }
        });
    },

    travelBookAttention: function (data) {
        if (!cleverapps.travelBook) {
            return;
        }

        cleverapps.travelBook.listAllPages().forEach((page) => {
            if (data[page.id]) {
                cleverapps.dataLoader.save(DataLoader.TYPES.TRAVEL_BOOK_ATTENTION + page.id, data[page.id]);
            } else {
                cleverapps.dataLoader.remove(DataLoader.TYPES.TRAVEL_BOOK_ATTENTION + page.id);
            }
        });
    },

    clan: function () {
        if (cleverapps.userClan && cleverapps.clanMessages) {
            cleverapps.userClan.load();
            cleverapps.clanMessages.loadMessages();
        }
    },

    ads: function (data) {
        if (!data || (typeof data === "object" && !data.enabled)) {
            connector.ads.getPlugin().setStatus(connector.STATUS_DISABLED);
            cleverapps.flags.disableReconnect = true;
        }
        cleverapps.rewardedAdsManager.prerollStatus = data.prerollStatus;
    },

    subscription: function (data) {
        data[connector.info.source] = Object.values(data)[0];
        cleverapps.subscription.updateInfo(data);
    },

    specialEnergyOffer: function (data) {
        cleverapps.dataLoader.save(DataLoader.TYPES.SPECIAL_LIVES_OFFER_LAST_TIME, data);
    },

    events: function (serverData) {
        if (!cleverapps.eventManager) {
            return;
        }
        cleverapps.eventManager.events = serverData.map((eventData) => new cleverapps.Event(eventData));
    },

    stickersBookAttention: function (data) {
        if (!cleverapps.stickersBook) {
            return;
        }
        cleverapps.stickersBook.updateAttention(data);
    },

    noAds: function (state) {
        if (state) {
            cleverapps.noAds.enable();
        } else {
            cleverapps.noAds.reset();
        }
    },

    rumble: function (data) {
        cleverapps.dataLoader.save(DataLoader.TYPES.RUMBLE, data);
    },

    knockout: function (data) {
        cleverapps.dataLoader.save(`${DataLoader.TYPES.COMPETITION}_knockout`, data);
    },

    energyProducts: function (data) {
        cleverapps.dataLoader.save(DataLoader.TYPES.ENERGY_PRODUCTS, data);
    },

    adsLimits: function (data) {
        cleverapps.dataLoader.save(DataLoader.TYPES.ADS_LIMITS_DATA, data);

        cleverapps.adsLimits.load();
    },

    friendRequests: function (serverData) {
        if (levels.friendRequests) {
            cleverapps.dataLoader.save(DataLoader.TYPES.FRIEND_REQUESTS, {
                notProcessed: serverData.notProcessed,
                processedIds: serverData.processedIds
            });
        }
    }
};

SnapshotSyncers.extractData = function (callback) {
    const waiter = cleverapps.wait(2, () => {
        const extractors = {};
        for (const type in CustomSyncers.extractors) {
            if (type.indexOf("units_") === 0) {
                continue;
            }

            extractors[type] = CustomSyncers.extractors[type];
        }

        for (const type in SnapshotSyncers.extractors) {
            extractors[type] = SnapshotSyncers.extractors[type];
        }

        const data = {};
        data.date = Date.now();
        data.slot = typeof Game !== "undefined" && Game.currentGame && Game.currentGame.slot || typeof Merge !== "undefined" && Merge.currentMerge && Merge.currentMerge.location.slot;

        for (const type in extractors) {
            const extractor = extractors[type];
            if (!extractor) {
                continue;
            }

            if (extractor.useSlots) {
                if (data.slot) {
                    data[type] = extractor(data.slot);
                }
            } else {
                data[type] = extractor();
            }
        }

        callback(data);
    });

    if (cleverapps.userClan && !cleverapps.config.norest) {
        cleverapps.userClan.load(waiter, waiter, {
            ignoreNoRest: true
        });
        cleverapps.clanMessages.loadMessages(waiter);
    } else {
        waiter();
        waiter();
    }
};

SnapshotSyncers.registerBySlots = function (name, extractor, importer) {
    Meta.SLOTS.forEach((slot) => {
        this.extractors[name + slot] = function () {
            return extractor(slot);
        };

        this.importers[name + slot] = function (serverData) {
            return importer(slot, serverData);
        };
    });

    this.extractors[`${name}_snapshot`] = extractor;
    this.importers[`${name}_snapshot`] = importer;

    extractor.useSlots = true;
    importer.useSlots = true;
};

SnapshotSyncers.registerBySlots("units", (slot) => {
    if (!cleverapps.unitSavers) {
        return;
    }
    const unitSaver = cleverapps.unitSavers.getInstance(slot);
    const unitSyncer = unitSaver.unitSyncer;
    const result = {};

    for (const syncerCode in unitSyncer.syncers) {
        const familySyncer = unitSyncer.syncers[syncerCode];

        result[familySyncer.code] = familySyncer.getInfo();
    }

    return result;
}, (slot, serverData) => {
    if (!cleverapps.unitSavers) {
        return;
    }
    const unitSaver = cleverapps.unitSavers.getInstance(slot);
    const unitSyncer = unitSaver.unitSyncer;
    unitSyncer.updateInfo(serverData, true);
});

SnapshotSyncers.registerBySlots("map2d", (slot) => cleverapps.dataLoader.load(DataLoader.TYPES.MAP2D + slot), (slot, serverData) => {
    cleverapps.dataLoader.save(DataLoader.TYPES.MAP2D + slot, serverData);
});

SnapshotSyncers.registerBySlots("freeworker", (slot) => cleverapps.dataLoader.load(DataLoader.TYPES.FREE_WORKER + slot), (slot, serverData) => {
    if (serverData) {
        cleverapps.dataLoader.save(DataLoader.TYPES.FREE_WORKER + slot, serverData);
    } else {
        cleverapps.dataLoader.remove(DataLoader.TYPES.FREE_WORKER + slot);
    }
});

SnapshotSyncers.registerBySlots("thirdElements", (slot) => cleverapps.dataLoader.load(DataLoader.TYPES.THIRDELEMENTS_PLANNER + slot), (slot, serverData) => {
    if (serverData) {
        cleverapps.dataLoader.save(DataLoader.TYPES.THIRDELEMENTS_PLANNER + slot, serverData);
    } else {
        cleverapps.dataLoader.remove(DataLoader.TYPES.THIRDELEMENTS_PLANNER + slot);
    }
});
