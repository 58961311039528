/**
 * Created by mac on 7/8/18
 * @return {boolean}
 */

const InviteButton = cleverapps.UI.Button.extend({
    ctor: function (options) {
        this.options = options || {};

        this.options.onSuccess = this.options.onSuccess || function () {};

        const styles = cleverapps.styles.InviteButton;

        this._super({
            type: this.options.type,
            width: this.options.width || styles.width,
            height: this.options.height || styles.height,
            onClicked: this.onClicked.bind(this),
            content: options.content,
            text: options.content ? undefined : "InviteButton.Text"
        });
    },

    onClicked: function () {
        const channel = this.options.channel || cleverapps.Random.mathChoose(connector.platform.listShareChannels()) || "invite";

        cleverapps.SocialManager.share(channel, "default", (success) => {
            if (success) {
                this.options.onSuccess();
            }
        });
    }
});

cleverapps.styles.InviteButton = {
    width: 200,
    height: 110
};
