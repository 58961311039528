/**
 * Created by vladislav on 08.10.2019
 */

const RewardsListComponent = cc.Node.extend({
    ctor: function (reward, options) {
        this._super();

        this.reward = reward instanceof RewardsList ? reward : new RewardsList(reward, options);
        this.options = options || {};

        this.createContent();
        if (!this.options.noShowControls) {
            cleverapps.focusManager.showControlsWhileFocused(this.reward.listControls());
        }
    },

    createContent: function () {
        const styles = cleverapps.styles.RewardsListComponent;
        const rewards = this.options.filterCustomAnimation ? this.reward.commonRewards : this.reward.listRewards();
        if (this.options.fitToBox && !this.options.small) {
            this.autoIconSize();
        }

        const targets = this.targets = [];

        rewards.forEach(function (reward) {
            if (reward.getAmount() && !RewardTypes[reward.type].invisible) {
                targets.push(this.createOneReward(reward));
            }
        }, this);

        this.content = new cleverapps.WrapGridLayout(targets, {
            reverseRows: this.options.reverseRows,
            columns: this.options.columns || this.calcColumnsCount(targets.length),
            fitToBox: this.options.fitToBox,
            margin: {
                x: this.options.margin && this.options.margin.x !== undefined ? this.options.margin.x : styles.margin.x,
                y: this.options.margin && this.options.margin.y !== undefined ? this.options.margin.y : styles.margin.y
            }
        });

        this.addChild(this.content);
        this.setContentSize2(this.content);
        this.setAnchorPoint(0.5, 0.5);
        this.content.setAnchorPoint(0.5, 0.5);
        this.content.setPositionRound(this.width / 2, this.height / 2);
    },

    autoIconSize: function () {
        const area = this.options.fitToBox.width * this.options.fitToBox.height;
        const rewards = this.options.filterCustomAnimation ? this.reward.commonRewards : this.reward.listRewards();

        const smallArea = rewards.reduce((area, reward) => {
            if (reward.getAmount()) {
                const contentSize = reward.getSmallIcon().getContentSize();
                area += contentSize.width * contentSize.height;
            }
            return area;
        }, 0);

        this.options.small = smallArea && area / smallArea <= 1.5;
    },

    createIcon: function (reward) {
        let icon;
        if (this.options.small) {
            icon = reward.getSmallIcon();
        } else {
            icon = reward.getIcon();
        }

        return icon;
    },

    createOneReward: function (reward) {
        const styles = cleverapps.styles.RewardsListComponent;

        const icon = this.createIcon(reward);
        let prefix = this.options.noPrefix ? "" : "x";
        if (this.options.prefix) {
            prefix = this.options.prefix;
        }

        let text = reward.getText(prefix);
        if (this.options.hideTextForSingleReward && text === `${prefix}1`) {
            text = "";
        }
        const textNode = cleverapps.UI.generateTTFText(text, this.options.font || cleverapps.styles.FONTS.REWARDS_LIST_TEXT || cleverapps.styles.FONTS.WINDOW_TEXT);

        let iconWrap;
        let textWrap;
        if (this.options.iconWrap) {
            cleverapps.UI.fitToBox(icon, {
                height: this.options.iconWrap.height,
                maxScale: icon.scale
            });

            iconWrap = new cc.Node();
            iconWrap.setAnchorPoint(0.5, 0.5);
            iconWrap.setContentSize(this.options.iconWrap.width || icon.width * icon.scale, this.options.iconWrap.height || icon.height * icon.scale);
            iconWrap.addChild(icon);
            icon.setPositionRound(iconWrap.width / 2, iconWrap.height / 2);
        }

        if (this.options.textWrap) {
            textWrap = new cc.Node();
            textWrap.setAnchorPoint(0.5, 0.5);
            textWrap.setContentSize(this.options.textWrap);

            textWrap.addChild(textNode);
            textNode.setPositionRound(textWrap.width / 2, textWrap.height / 2);
        }

        const target = new cleverapps.Layout([iconWrap || icon, textWrap || textNode], {
            direction: this.options.textDirection !== undefined ? this.options.textDirection : cleverapps.UI.VERTICAL,
            margin: this.options.textMargin !== undefined ? this.options.textMargin : styles.textMargin
        });
        target.icon = icon;
        target.text = textNode;
        target.reward = reward;

        if (this.options.shine) {
            const shineParent = iconWrap || target.icon;
            target.shine = StandartAnimations.rays(shineParent || target.icon);
        }

        return target;
    },

    showUp: function (callback) {
        const startOffsetY = cleverapps.styles.RewardsListComponent.showUp.startOffsetY;
        callback = cleverapps.wait(2, callback || (() => {}));

        if (!this.targets.length) {
            callback();
        }

        const actions = [];

        this.targets.forEach((target, id) => {
            if (target.shine) {
                target.shine.setVisible(false);
            }

            target.setCascadeOpacityEnabledRecursively(true);
            target.setOpacity(0);
            target.setPositionRound(target.x, target.y - startOffsetY);

            actions.push(new cc.TargetedAction(target, new cc.Sequence(
                new cc.Spawn(
                    new cc.MoveBy(0.6, 0, startOffsetY).easing(cc.easeOut(2)),
                    new cc.FadeIn(0.6).easing(cc.easeIn(2))
                ),
                new cc.Sequence(
                    new cc.DelayTime(0.1),
                    new cc.CallFunc(() => {
                        if (target.shine) {
                            target.shine.setVisible(true);
                            target.shine.setAnimationAndIdleAfter("open", "idle");
                        }
                    }),
                    new cc.CallFunc(() => {
                        if (id === this.targets.length - 1) {
                            callback();
                        }
                    })
                )
            )));
        });

        const customRewardsActions = this.reward.customRewards.map((reward) => reward.collectRewardsAction());

        actions.push(new cc.Sequence(
            new cc.DelayTime(0.8),
            new cc.Sequence(customRewardsActions),
            new cc.CallFunc(callback)
        ));

        this.runAction(new cc.Spawn(actions));
    },

    receiveRewards: function () {
        this.reward.receiveRewards();
    },

    receiveRewardsAction: function (options) {
        options = options || {};

        let actions = [];

        if (!options.noWinEffect) {
            cleverapps.audio.playSound(bundles.main.urls.win_reward_effect);
        }

        this.targets.forEach((target, index) => {
            if (target.shine) {
                actions.push(this._shineAction(target, index));
            }

            if (target.text && !options.noFadeOut) {
                actions.push(this._textAction(target, index, options));
            }

            const collect = function () {
                const delay = options.delay || 0;
                return target.reward.collectRewardsAction(target.icon, {
                    delay: delay + index * 300,
                    beginScale: target.icon.adjustScaleTo(cleverapps.scenes.getMovingNode(target.icon)).y,
                    hideSource: !options.noFadeOut,
                    small: Boolean(this.options.small),
                    sound: RewardTypes[target.reward.type].getCollectRewardSound && RewardTypes[target.reward.type].getCollectRewardSound(),
                    flyingAnimation: options.flyingAnimation
                });
            }.bind(this);

            actions.push(this._iconAction(target, index, options, collect));
        });

        actions = new cc.Spawn(actions);

        if (options.callback) {
            actions = new cc.Sequence(actions, new cc.CallFunc(options.callback));
        }

        return actions;
    },

    receiveRewardsAnimation: function (options) {
        const scene = cleverapps.scenes.getRunningScene();
        scene.runAction(this.receiveRewardsAction(options));
    },

    _shineAction: function (target, index) {
        return new cc.TargetedAction(target.shine, new cc.Sequence(
            new cc.DelayTime(index * 0.3),
            new cc.CallFunc(target.shine.setAnimation.bind(target.shine, 1, "close", false))
        ));
    },

    _textAction: function (target, index, options) {
        const delay = options.fadeDelay !== undefined ? options.fadeDelay : 0.3;
        target.text.setCascadeOpacityEnabledRecursively(true);
        return new cc.TargetedAction(target.text, new cc.Sequence(
            new cc.DelayTime(index * delay),
            new cc.FadeOut(0.75)
        ));
    },

    _iconAction: function (target, index, options, collect) {
        target.icon.setLocalZOrder(this.targets.length - index);
        if (options.iconAction) {
            return new cc.TargetedAction(target.icon, new cc.Spawn(
                options.iconAction(target.icon),
                collect()
            ));
        } 
        return collect();
    },

    calcColumnsCount: function (rewardsCount) {
        if (rewardsCount <= 3) {
            return rewardsCount;
        }

        if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
            return 4;
        }

        if (rewardsCount === 4 && cleverapps.resolution.mode !== cleverapps.WideMode.HORIZONTAL) {
            return 2;
        }

        return 3;
    }
});

cleverapps.styles.RewardsListComponent = {
    margin: {
        x: 30,
        y: 30
    },
    shine: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 40 }
    },
    showUp: {
        startOffsetY: 25,
        floatingOffset: 4
    },
    textMargin: 10
};
