/**
 * Created by denis on 31 july 2020
 */

const GamePlayer = function (options) {
    this.options = options || {};
};

GamePlayer.prototype.play = function (options, callback) {
    this.finger = FingerView.create(this.options.finger);
    const player = this.player = new ActionPlayer();
    const savedFingerRemove = FingerView.remove;
    FingerView.remove = function () {};
    this.options.fingerSpeedMultiplier = options.fingerSpeedMultiplier || 1;

    let lastActionTime = 0;

    options.actions.filter((action) => !action.disabled).forEach((action, index, actions) => {
        let delay = options.delay || 0;
        if (index === 0) {
            delay += options.initialDelay || 0;
        }
        player.add((f) => {
            cleverapps.timeouts.setTimeout(f, delay * 1000);
        });
        
        if (index === 0 && options.onStartPlay) {
            player.add((f) => {
                options.onStartPlay();
                f();
            });
        }

        if (action.beforeStartDelay) {
            const actionDelay = Math.max(0, Math.min(action.beforeStartDelay - lastActionTime, 10));
            player.add((f) => {
                cleverapps.timeouts.setTimeout(f, actionDelay * 1000);
            });
        }

        player.add((f) => {
            let duration = 1;
            if (actions[index + 1] && actions[index + 1].beforeStartDelay) {
                duration = actions[index + 1].beforeStartDelay;
            }

            const actionStart = Date.now();
            this.playSingleAction(action, () => {
                lastActionTime = (Date.now() - actionStart) / 1000;
                f();
            }, duration);
        });
    });

    player.onComplete(() => {
        FingerView.remove = savedFingerRemove;
        FingerView.remove(this.finger);

        if (callback) {
            callback();
        }
    });

    player.play();
};

GamePlayer.prototype.stop = function () {
    if (this.player) {
        this.player.stop();
    }
};

GamePlayer.prototype.playSingleAction = function (action, f, duration) {
    let game;
    if (typeof Game !== "undefined" && Game.currentGame) {
        game = Game.currentGame;
    } else if (typeof Merge !== "undefined" && Merge.currentMerge) {
        game = Merge.currentMerge;
    }

    if (game && game.counter.isActive()) {
        setTimeout(this.playSingleAction.bind(this, action, f, duration), 50);
        return;
    }

    if (action.beforeActionSeed) {
        cleverapps.Random.seed(action.beforeActionSeed);
    }

    const startNode = this.findInterestingNode(action, "startDebugId");
    if (!startNode && action.type !== "finish") {
        setTimeout(this.playSingleAction.bind(this, action, f, duration), 50);
        return;
    }

    const endNode = this.findInterestingNode(action, "endDebugId");
    switch (action.type) {
        case "touch":
            this.touch(f, {
                startTarget: startNode,
                startPoint: cc.p(action.location.x * startNode.width, action.location.y * startNode.height)
            });
            break;
        case "drag":
            this.touch(f, {
                startTarget: startNode,
                startPoint: cc.p(action.startPoint.x * startNode.width, action.startPoint.y * startNode.height),
                path: action.path && action.path.map((point) => cc.p(point.x * startNode.width, point.y * startNode.height)),
                endTarget: endNode,
                endPoint: cc.p(action.endPoint.x * endNode.width, action.endPoint.y * endNode.height)
            });
            break;
        case "finish":
            this.fingerFadeOut(() => {
                cleverapps.timeouts.setTimeout(f, 500);
            });
            break;
        case "zoom":
            var mouseEvent = cc.inputManager.getMouseEvent(
                cc.p(startNode.width / 2, startNode.height / 2),
                cc.inputManager.getHTMLElementPosition(cc.game.canvas),
                cc.EventMouse.SCROLL
            );
            mouseEvent.setButton(0);
            mouseEvent.setScrollData(0, action.scrollY);
            cc.eventManager.dispatchEvent(mouseEvent);
            cleverapps.timeouts.setTimeout(f, 30);
            break;
        default:
            console.log(`Skipped step: ${action.type}`);
            f();
    }

    return true;
};

GamePlayer.prototype.findInterestingNode = function (action, prop) {
    if (prop && action[prop]) {
        return $({ debugId: action[prop] }).objects[0];
    }

    return action.debugId && $({ debugId: action.debugId }).objects[0];
};

GamePlayer.prototype.touch = function (f, options) {
    let path = [{
        node: options.startTarget,
        position: options.startPoint
    }];

    if (options.path) {
        options.path.forEach((point) => {
            path.push({
                node: options.startTarget,
                position: point
            });
        });
    }

    if (options.endPoint) {
        path.push({
            node: options.endTarget || options.startTarget,
            position: options.endPoint
        });
    }

    path = path.map((target) => this.finger.prepareTarget(target));

    const actions = [];
    const gamePlaySpeed = this.options.fingerSpeedMultiplier * (GamePlayer.MOVE_SPEED[cleverapps.config.name] || GamePlayer.MOVE_SPEED[cleverapps.config.type]);

    let target = path[0];
    let duration = this.lastFingerTarget ? this.finger.getMoveDuration(this.lastFingerTarget, target, gamePlaySpeed) : 0;

    const touchId = Math.ceil(Math.random() * 100);
    const key = `${this.finger.__instanceId}_${touchId}`;
    let touch;

    actions.push(this.finger.moveTo(undefined, target, { duration }));

    actions.push(this.finger.swipePress());

    actions.push(new cc.CallFunc(() => {
        touch = {
            _prevPoint: this.convertToScreenSpace(this.finger, this.finger.finger),
            _point: this.convertToScreenSpace(this.finger, this.finger.finger),
            _fromGamePlayer: true,
            getID: function () {
                return touchId;
            }
        };
        cc.inputManager.handleTouchesBegin([touch]);
        cc.director.getScheduler().schedule(() => {
            touch._prevPoint = touch._point;
            touch._point = this.convertToScreenSpace(this.finger, this.finger.finger);
            cc.inputManager.handleTouchesMove([touch]);
        }, this.finger, 0, cc.REPEAT_FOREVER, 0, false, key);
    }));

    let sumDuration = 0;
    for (let i = 1; i < path.length; ++i) {
        target = path[i];
        duration = Math.max(0.005, this.finger.getMoveDuration(target, path[i - 1], gamePlaySpeed));
        sumDuration += duration;
        if (sumDuration > 0.05 || i === path.length - 1) {
            actions.push(this.finger.moveTo(undefined, target, { duration: sumDuration }));
            sumDuration = 0;
        }
    }
    this.lastFingerTarget = target;

    actions.push(new cc.CallFunc(() => {
        cc.director.getScheduler().unschedule(key, this.finger);
        cc.inputManager.handleTouchesEnd([touch]);
    }));

    actions.push(this.finger.swipeRelease());
    actions.push(new cc.CallFunc(f));

    this.finger.play({
        actions,
        runOnce: true,
        keepShown: true
    });
};

GamePlayer.prototype.convertToScreenSpace = function (target, location) {
    const scene = cleverapps.scenes.getRunningScene();
    const coordinates = target.convertToWorldSpace(location);
    const normalizedCoordinates = cc.p(coordinates.x / scene.width * window.devicePixelRatio, coordinates.y / scene.height * window.devicePixelRatio);

    const screenRect = cc.game.canvas.getBoundingClientRect();
    return cc.p(normalizedCoordinates.x * screenRect.width, normalizedCoordinates.y * screenRect.height);
};

GamePlayer.prototype.fingerFadeOut = function (f) {
    delete this.finger.isShown;

    const actions = [];
    actions.push(this.finger.swipeRelease());
    actions.push(new cc.CallFunc(f));

    this.finger.play({
        actions,
        runOnce: true,
        keepShown: true
    });
};

GamePlayer.MOVE_SPEED = {
    board: 1200,
    match3: 900,
    merge: 700,
    tile3: 2000,

    tripeaks: 1000,
    scramble: 2200
};
