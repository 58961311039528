/**
 * Created by evgenijsenkevic on 26.12.2024
 */
const FriendsListRequestsToolbarItem = function () {
    ToolbarItem.call(this, ToolbarItem.FRIENDS_LIST_REQUESTS);

    this.enable();
};

FriendsListRequestsToolbarItem.prototype = Object.create(ToolbarItem.prototype);
FriendsListRequestsToolbarItem.prototype.constructor = FriendsListRequestsToolbarItem;

FriendsListRequestsToolbarItem.prototype.isVisible = function () {
    return levels.user.checkAvailable(cleverapps.Availables.FRIENDBALLOON);
};

FriendsListRequestsToolbarItem.prototype.onClick = function () {
    this.unmark();

    cleverapps.tutorial.trigger("send_gift_for_friends");

    cleverapps.focusManager.display({
        focus: "FriendsSendPresentWindow",
        action: function (f) {
            cleverapps.SocialManager.enforceLogin((code) => {
                if (code !== cleverapps.CODE_SUCCEED) {
                    f();
                    return;
                }

                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.FRIEND_BALLOON_CLICK_ICON, { value: 1 });

                const userId = connector.platform.getUserID();

                SelectFriendWindow.CreateAndSendRequest("sendGift", {
                    push_code: "friend_balloon",
                    friendId: userId,
                    request_type: levels.FriendRequest.TYPE_SENT_UNIT,
                    id: Math.random().toString(36).slice(2),
                    date: Date.now()
                }, (code, friends) => {
                    if (code === cleverapps.CODE_SUCCEED) {
                        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.FRIEND_BALLOON_FRIEND_SUCCESS, { value: 1 });
                        friends.forEach((friend) => {
                            levels.FriendRequests.sendGift(friend);
                        });
                    } else if (code === cleverapps.CODE_FAILED) {
                        cleverapps.notification.create("FriendGift.error");
                        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.FRIEND_BALLOON_FRIEND_ERROR, { value: 1 });
                        cleverapps.throwAsync(
                            `connector.social.requestCallback: ${JSON.stringify(friends)}`
                        );
                    }

                    f();
                });
            });
        }
    });
};
