/**
 * Created by andrey on 7/18/17.
 */

cleverapps.Audio = function () {
    this.cache = {};

    if (WebViewAudioEngine.IsApplicable()) {
        cc.sys.isWebViewAudio = true;
        cc.audioEngine = new WebViewAudioEngine();
    }

    this.disk = undefined;
    this.muted = false;
    this.volume = 1;
    this.currentFade = undefined;
    this.checkPlatformControls();
};

cleverapps.Audio.prototype.checkPlatformControls = function () {
    if (connector.platform.hasAudioControls) {
        const update = function (isEnabled) {
            if (isEnabled) {
                this.unmute();
            } else {
                this.mute();
            }
        }.bind(this);
        connector.platform.on("audioToggle", update);
        update(connector.platform.isAudioEnabled);
    }
};

cleverapps.Audio.prototype.insertDisk = function (disk) {
    this.eject();

    this.disk = disk && disk.resolve();
    this.onChangeMusic();
};

cleverapps.Audio.prototype.eject = function () {
    this.setVolume(1);
    this.stopFade();

    if (this.disk) {
        this.disk = undefined;
        this.onChangeMusic();
    }
};

cleverapps.Audio.prototype.mute = function () {
    if (this.muted || cleverapps.gameModes.noMute || connector.platform.oneOf(connector.INSTANT) && connector.info.os === cc.sys.OS_IOS && connector.info.isInApp) {
        return;
    }

    console.log("Audio mute");

    this.muted = true;
    this.onChangeMusic();

    if (cc.Audio.gainNode) {
        cc.Audio.gainNode.gain.value = 0;
    }
};

cleverapps.Audio.prototype.unmute = function () {
    if (!this.muted || cleverapps.gameModes.noMute || connector.platform.oneOf(connector.INSTANT) && connector.info.os === cc.sys.OS_IOS && connector.info.isInApp) {
        return;
    }

    console.log("Audio unmute");

    this.muted = false;
    this.onChangeMusic();

    if (cc.Audio.gainNode) {
        cc.Audio.gainNode.gain.value = 1;
    }
};

cleverapps.Audio.prototype.onChangeMusic = function () {
    if (this.disk && !this.muted && cleverapps.settings.music && !cc.game.isPaused()) {
        this.logSound(this.disk);
        if (this.volume !== this.realVolume) {
            this.setVolume(this.volume);
        }

        const audio = cc.loader.getRes(this.disk.getUrl());
        if (cc.sys.isWebViewAudio || audio && (!audio.isLoaded || audio.isLoaded())) {
            cc.audioEngine.playMusic(this.disk.getUrl(), true);
        }
    } else {
        cc.audioEngine.stopMusic();
    }
};

cleverapps.Audio.prototype.onSoundsLoaded = function (sounds) {
    if (this.disk && sounds.indexOf(this.disk.getUrl()) !== -1) {
        this.onChangeMusic();
    }
};

cleverapps.Audio.prototype.stopFade = function () {
    if (this.currentFade) {
        cleverapps.timeouts.clearInterval(this.currentFade);
        delete this.currentFade;
    }
};

cleverapps.Audio.prototype.setVolume = function (volume) {
    this.volume = volume;

    if (cc.audioEngine.canChangeMusicVolume() && !this.muted && cleverapps.settings.music && !cc.game.isPaused()) {
        this.realVolume = volume;
        cc.audioEngine.setMusicVolume(volume);
    }
};

cleverapps.Audio.prototype.fadeOut = function (fadeTime) {
    this.setVolume(1);
    this.stopFade();

    if (!this.disk) {
        return;
    }

    this._fade(fadeTime, -1);
};

cleverapps.Audio.prototype.fadeIn = function (fadeTime) {
    this.stopFade();

    if (!fadeTime) {
        this.setVolume(1);
        return;
    }

    this.setVolume(0);

    if (!this.disk) {
        return;
    }

    this._fade(fadeTime, 1);
};

cleverapps.Audio.prototype._fade = function (fadeTime, sign) {
    const FADE_TIME = fadeTime * 1000 || 400;
    const INTERVAL = 20;

    let steps = FADE_TIME / INTERVAL;
    const step = 1 / steps * sign;

    this.currentFade = cleverapps.timeouts.setInterval(() => {
        const volume = Math.max(0, Math.min(1, Math.floor((this.volume + step) * 1000) / 1000));
        this.setVolume(volume);
        steps--;

        if (steps === 0) {
            if (sign < 0) {
                this.eject();
            }
            this.stopFade();
        }
    }, INTERVAL);
};

cleverapps.Audio.prototype.playSound = function (soundRes, options) {
    if (!cleverapps.settings.sound || cc.game.isPaused() || this.muted) {
        return;
    }
    if (!cleverapps.Audio.isAudioContextRunning()) {
        return;
    }

    if (soundRes) {
        soundRes = soundRes.resolve();
    }
    if (!soundRes) {
        return;
    }

    options = options || {};
    this.logSound(soundRes, options);

    let audio = this.cache[soundRes.getUrl()];
    const throttle = options.throttle !== undefined ? options.throttle : cleverapps.Audio.THROTTLE_AUDIO;
    if (audio && audio.getPlaying() && audio.startPlaying + throttle > Date.now()) {
        return;
    }

    const fromPool = (cc.audioEngine._audioPool[soundRes.getUrl()] || []).filter((audio) => !audio.getPlaying())[0];
    if (fromPool) {
        cc.audioEngine.stopEffect(fromPool);
    }

    audio = cc.loader.getRes(soundRes.getUrl());
    if (cc.sys.isWebViewAudio || audio && (!audio.isLoaded || audio.isLoaded())) {
        audio = cc.audioEngine.playEffect(soundRes.getUrl());
        audio.startPlaying = Date.now();
        this.cache[soundRes.getUrl()] = audio;
        return audio;
    }

    if (soundRes.isLazy()) {
        cc.loader.load(soundRes.getUrl());
    }
};

cleverapps.Audio.prototype.stopSound = function (audio) {
    if (audio instanceof VirtualResource) {
        audio = audio.resolve();
        audio = audio && this.cache[audio.getUrl()];
    }

    cc.audioEngine.stopEffect(audio);
};

cleverapps.Audio.resumeAudioContext = function () {
    const context = cc.Audio._context;
    if (!context) {
        return;
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/BaseAudioContext/state
    if (context.state === "interrupted") {
        context.resume();
    } else if (context.state === "suspended") {
        context.suspend();
        context.resume();
    }
};

cleverapps.Audio.isAudioContextRunning = function () {
    if (connector.platform.oneOf(connector.WECHAT)) {
        return true;
    }

    const context = cc.Audio._context;
    return context && context.state === "running";
};

cleverapps.Audio.THROTTLE_AUDIO = 250;

cleverapps.Audio.prototype.logSound = function (sound, options) {
    if (cleverapps.config.debugMode && cleverapps.keyboardController.isPressed(cc.KEY.s)) {
        console.log("--------------------- sound logging ----------------------");
        console.log("sound file", sound.originalPath());
        if (options) {
            console.log("sound", sound.key, "options", options);
        }
        console.log("----------------------------------------------------------");
    }
};

cc.audioEngine.canChangeMusicVolume = function () {
    return cc.audioEngine._currMusic && cc.audioEngine._currMusic.setVolume;
};

cc.Audio.prototype.isLoaded = function () {
    return !!this._element;
};

cc.Audio.prototype.play = cleverapps.extendFunc(cc.Audio.prototype.play, function () {
    // eslint-disable-next-line prefer-spread,prefer-rest-params
    this._super.apply(this, arguments);

    if (cc.game.isPaused()) {
        cleverapps.throwAsync(`Play audio error: game is paused - ${this.src}`);
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.AUDIO_PLAY_PAUSED_GAME);
    }
});