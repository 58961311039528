/**
 * Created by Andrey Popov on 6/3/21
 */

const MenuBar = function () {
    this.items = MenuBar.listItems().map((itemConfig) => {
        const ModelClass = itemConfig.modelClass;
        return itemConfig.modelClass ? new ModelClass(itemConfig) : new MenuBarItem(itemConfig);
    });
};

MenuBar.listItems = function () {
    if (cleverapps.config.demoMode) {
        return [];
    }

    const items = [];

    if (typeof GameLevelMenuBarItem !== "undefined" && cleverapps.config.subtype !== "merge2") {
        items.push(GameLevelMenuBarItem);
    }

    if (["klondike"].indexOf(cleverapps.config.type) === -1) {
        items.push(GoldMenuBarItem);
    }

    if (typeof LivesMenuBarItem !== "undefined") {
        items.push(LivesMenuBarItem);
    }

    if (cleverapps.config.soft) {
        items.push(SoftMenuBarItem);
    }

    if (typeof GardenProgressMenubarItem !== "undefined") {
        items.push(GardenProgressMenubarItem);
    }

    if (typeof OrderStarsMenuBarItem !== "undefined") {
        items.push(OrderStarsMenuBarItem);
    }

    if (cleverapps.config.subtype !== "merge2") {
        if (typeof WandsMenuBarItem !== "undefined") {
            items.push(WandsMenuBarItem);
        }

        if (typeof WorkersMenuBarItem !== "undefined") {
            items.push(WorkersMenuBarItem);
        }

        if (typeof MethaStarsMenuBarItem !== "undefined") {
            items.push(MethaStarsMenuBarItem);
        }

        if (typeof ExpMenuBarItem !== "undefined") {
            items.push(ExpMenuBarItem);
        }
    }

    return items;
};

MenuBar.prototype.getSceneItems = function () {
    if (cleverapps.environment.isEditorScene()) {
        return [];
    }

    const items = [];
    this.items.forEach((item) => {
        if (item.isAvailableOnScene()) {
            item.init();
            items.push(item);
        } else {
            item.clean();
        }
    });
    return items;
};

MenuBar.prototype.stopItems = function () {
    this.items.forEach((item) => {
        item.stop();
    });
};

MenuBar.prototype.showForce = function (name) {
    let result;

    const item = this.items.find((item) => item.name === name);

    cleverapps.focusManager.display({
        focus: `${name}MenuBarItemForce`,
        control: `MenuBar${name}`,
        actions: [
            function (f) {
                result = true;
                cleverapps.timeouts.setTimeout(f, 500);
            },

            function (f) {
                item.onShowForce(item.config.force);
                
                cleverapps.forces.onceForceClosed = f;
            }
        ]
    });

    return result;
};

MenuBar.prototype.clean = function () {
    this.items.forEach((item) => {
        item.clean();
    });
};