/**
 * Created by Oleg on 06.03.2020.
 */

const ControlButtonsView = cleverapps.Layout.extend({
    ctor: function () {
        const styles = cleverapps.styles.ControlButtonsView;

        this.buttons = ControlButtonsView.controlButtons.filter((item) => item.logic.isAvailableInEpisode()).map((item) => new ControlButton(item));

        this._super(this.buttons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
    }
});

ControlButtonsView.controlButtons = [
    {
        type: "default",
        logic: SwitchAdminButton,
        icon: bundles.controlbuttons.frames.switch_icon_png
    },

    {
        type: "alternate",
        logic: ExitControlButton,
        icon: bundles.controlbuttons.frames.back_icon_png
    },

    {
        type: "default",
        logic: MenuControlButton,
        icon: bundles.controlbuttons.frames.menu_icon_png
    },

    {
        type: "default",
        logic: PauseControlButton,
        icon: bundles.controlbuttons.frames.pause_png
    }
];

cleverapps.styles.ControlButtonsView = {
    margin: 16
};
