/**
 * Created by Mikhail Menshenin on 24.07.2024
 */

const TutorialScenario = function (name, options) {
    this.name = name;
    this.options = options;
    this.priority = options.priority || 0;
    this.manual = options.manual;

    this.steps = [];
    this.setStep(0);
};

TutorialScenario.prototype.isAvailable = function () {
    return this.options.isAvailable();
};

TutorialScenario.prototype.start = function () {
    if (this.options.onStart) {
        this.options.onStart.call(this);
    }

    this.steps = this.options.listSteps();
    this.startStep();
};

TutorialScenario.prototype.startNextStep = function () {
    this.stepNo++;
    this.startStep();
};

TutorialScenario.prototype.startStep = function () {
    this.logStepStart();
    const step = this.getCurrentStep();
    if (step) {
        step.start();
    }
};

TutorialScenario.prototype.logStepStart = function () {
    let name = this.name;
    if (this.options.prepareNameForLogging) {
        name = this.options.prepareNameForLogging(name);
    }
    cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.STATS.TUTORIAL_STEP + name}_${this.stepNo}`);
};

TutorialScenario.prototype.getCurrentStep = function () {
    return this.steps[this.stepNo];
};

TutorialScenario.prototype.setStep = function (step) {
    this.stepNo = step;
};

TutorialScenario.prototype.onFinishScenario = function () {
    if (this.options.onFinish) {
        this.options.onFinish.call(this);
    }
};

TutorialScenario.BASIC_TUTORIAL_NAME = "basic";